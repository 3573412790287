@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ededed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner {
  margin-top: 65px;
  height: 520px;
}
.banner__body {
  padding: 140px 0 0 100px;
}

.banner__title {
  font-size: 42px;
  font-weight: 800;
  color: #ededed;
}

button {
  border: none;
  background-color: rgb(35, 41, 49, 0.5);
  padding: 10px 0;
  margin-bottom: 10px;
  width: 120px;
  margin-right: 10px;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 2px;
  font-size: 14;
  cursor: pointer;
  outline: none;
}
.button__link {
  text-decoration: none;
  color: #f1d18a;
}
button:hover {
  background-color: #f1d18a;
  color: #ededed;
}
button:hover .button__link {
  color: #ededed;
}
.button__link:hover {
  color: #ededed;
}

@media screen and (max-width: 600px) {
  .banner__body {
    padding-left: 30px;
  }
}
@media screen and (max-width: 278px) {
  .banner__title {
    font-size: 36px;
  }
}

.navbar {
  /* background-color: rgb(35, 41, 49, 0.5); */
  background-color: #232931;
  color: #ededed;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  transition-timing-function: ease-in;
  transition: all 0.5s;
}
.navbar__bg {
  background-color: #232931;
}

.navbar__icon {
  padding: 15px 0;
  margin-left: 30px;
  display: flex;
}

.navbar__icon img {
  height: 40px;
  object-fit: contain;
  margin-right: 5px;
}

.navbar__icon h1 {
  font-size: 14px;
  letter-spacing: 1.5px;
}

.navbar__menu {
  margin-right: 30px;
}

.navbar__menu ul {
  display: flex;
  list-style-type: none;
}

.navbar__menu ul li {
  padding: 15px 0;
  text-decoration: none;
  margin: 0 20px;
  cursor: pointer;
  transition: all 24ms ease;
  border-bottom: 2px solid transparent;
}
.navbar__menu ul li.active {
  border-bottom: 2px solid #f73859;
  font-weight: 500;
}

.navbar__menu ul li:hover {
  border-bottom: 2px solid #f73859;
  font-weight: 500;
}
.navbar__menu ul li ul {
  margin-left: -50px;
  position: absolute;
  top: 90px;
  display: block;
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.navbar__menu ul li:hover ul {
  opacity: 1;
  visibility: visible;
  top: 72px;
}
.navbar__menu ul li ul li {
  background-color: #232931;
  padding: 20px 15px;
}
.navbar__menu ul li ul li:hover {
  border-bottom: 0px;
  border-right: 2px solid #f73859;
}
.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  background-color: #ededed;
  margin: 4px 0;
  height: 4px;
  width: 25px;
  transition: all 0.3s ease;
}
.burger .line1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
          transform: rotate(-45deg) translate(-5px, 6px);
}
.burger .line2 {
  opacity: 0;
}
.burger .line3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
          transform: rotate(45deg) translate(-5px, -6px);
}

.navbar__menu__mobile {
  text-align: right;
  background-color: #232931;
  color: #ededed;
  width: 30%;
  height: 100%;
  display: block;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 70px;
  padding: 20px 30px 0 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
}
.link {
  color: #ededed;
  text-decoration: none;
}
.navbar__menu__mobile ul {
  list-style-type: none;
}
.navbar__menu__mobile ul li {
  list-style-type: none;
  cursor: pointer;
}
.navbar__menu__mobile ul li:hover {
  border-left: 2px solid #f73859;
  font-weight: 500;
}
.navbar__menu__mobile ul li ul li {
  display: none;
  padding-right: 20px;
  cursor: pointer;
}
.navbar__menu__mobile ul li:hover ul li {
  display: block;
}

@media screen and (max-width: 760px) {
  .navbar {
    background-color: #232931;
  }
  .burger {
    display: block;
  }
  .navbar__menu ul {
    display: none;
  }
  .navbar__active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media screen and (max-width: 550px) {
  .navbar__menu__mobile {
    width: 60%;
    height: 100%;
    display: block;
    position: fixed;
    right: 0;
    top: 70px;
    padding: 20px 10px 0 0;
  }
}
@media screen and (max-width: 360px) {
  .navbar__menu__mobile {
    width: 100%;
  }
}
@media screen and (max-width: 300px) {
  .navbar__icon {
    margin-left: 10px;
  }
}

@media screen and (max-width: 280px) {
  .navbar__menu__mobile {
    padding-top: 40px;
  }
  .navbar__icon h1 {
    display: none;
  }
}
@media screen and (max-width: 240px) {
  .navbar__menu__mobile {
    padding-top: 60px;
  }
}

.berita {
  background-color: #ededed;
  margin: 20px 10px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
  border-radius: 10px;
  width: 270px;
  height: 420px;
}

.img__berita {
  width: 270px;
  height: 270px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.title__berita {
  padding: 5px 15px;
  font-size: 18px;
  color: rgba(35, 41, 49);
  cursor: pointer;
}

.berita__container {
  margin-top: 20px;
  padding: 25px 100px;
}

.title__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  border-bottom: 2px solid #f73859;
  margin-left: 10px;
}

.berita__container__title {
  color: #232931;
  padding: 0;
}

.lihat__semua {
  color: #f73859;
  cursor: pointer;
  text-decoration: none;
}

.lihat__semua:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.berita__row {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.berita__row::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 920px) {
  .berita__container {
    padding: 25px 30px;
  }
}

@media screen and (max-width: 460px) {
  .berita__container {
    padding: 25px 10px;
  }
}

@media screen and (max-width: 410px) {
  .title__row {
    display: block;
    justify-content: start;
    align-items: flex-start;
  }
  .lihat__semua {
    margin-left: 10px;
    margin-top: 5px;
  }
}

.beranda {
  width: 100%;
  flex: 1 0 auto;
}

.footer {
  width: 100%;
  background-color: #232931;
  text-align: center;
  color: #ededed;
}

.footer__content {
  display: flex;
  padding: 20px 100px;
  text-align: center;
}

.kontak__kami,
.telpon__penting,
.lokasi {
  flex: 1 1;
  text-align: left;
  padding: 0 5px;
}
.footer__title {
  margin-top: 50px;
  font-size: 20px;
  font-weight: 300;
}

.footer__des {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
  line-height: 35px;
}

.copyright {
  padding: 20px 0;
  width: 100%;
  background-color: #232933;
}

.footer__link {
  text-decoration: none;
  color: #ededed;
  font-weight: 400;
}
.footer__maps {
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .footer__content {
    display: block;
  }
  .kontak__kami,
  .telpon__penting,
  .lokasi {
    padding: 0;
  }
}

@media screen and (max-width: 400px) {
  .footer__content {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 300px) {
  .footer__content {
    padding: 20px 5px;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-y: hidden;
}

.layanan {
  width: 100%;
  flex: 1 0 auto;
}
.body__layanan {
  margin-top: 70px;
  display: flex;
  padding: 20px 30px;
}
@media screen and (max-width: 663px) {
  .body__layanan {
    display: block;
  }
}

.slide__layanan {
  min-width: 200px;
  margin-right: 20px;
  text-align: center;
  background-color: #ededed;
  /* width: 210px; */
  display: block;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
}
.title__layanan {
  color: #f73859;
  font-size: x-large;
  font-weight: 700;
  padding: 10px 0;
}
.slide__layanan ul {
  list-style-type: none;
}
.slide__layanan ul li {
  padding: 10px 0;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
  cursor: pointer;
}
.slide__text {
  color: #232931;
  font-weight: 500;
  text-decoration: none;
}
.slide__layanan__mobile {
  min-width: 50px;
  text-align: center;
  padding: 15px 0;
  display: none;
  width: 100%;
}
.slide__layanan__mobile ul {
  list-style-type: none;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
}
.slide__layanan__mobile ul::-webkit-scrollbar {
  display: none;
}
.slide__layanan__mobile ul li {
  margin-right: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 190px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
  cursor: pointer;
}

@media screen and (max-width: 663px) {
  .slide__layanan {
    display: none;
  }
  .slide__layanan__mobile {
    display: flex;
  }
}

/* .body__layanan {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
} */

.content__layanan {
  background-color: #ededed;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
}

.content__layanan h1 {
  color: #f73859;
  margin-bottom: 25px;
}

.content__layanan span {
  color: #232931;
  line-height: 30px;
}

@media screen and (max-width: 233px) {
  .content__layanan {
    padding: 0;
  }
}

.page__berita {
  width: 100%;
  flex: 1 0 auto;
}

.page__berita__body {
  margin: 100px 40px 10px 30px;
  text-align: center;
  display: block;
}
.page__berita__body button {
  margin: 10px 0;
  width: 200px;
  color: #ededed;
  background-color: #f73859;
  transition: all 0.2s ease;
  outline: none;
}
.page__berita__body button:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  outline: none;
}
.page__berita__body__slider {
  margin-bottom: 200px;
}
.hidden {
  display: none;
}

.berita__slide {
  width: 100%;
  border-radius: 20px;
}
.berita__slide img {
  position: relative;
  border-radius: 20px;
  object-position: center;

  z-index: 1;
  object-fit: cover;
  height: 400px;
  width: 100%;
  box-sizing: border-box;
}

.berita__slide div {
  position: absolute;
  z-index: 2;
  top: 30px;
  /* background-color: rgba(35, 41, 49, 0.3); */
  background-color: rgba(247, 56, 89, 0.9);
  width: 300px;
  padding: 20px 30px;
  text-align: left;
  border-radius: 0 5px 5px 0;
  color: #ededed;
  font-weight: 600;
}

@media screen and (max-width: 430px) {
  .berita__slide div {
    width: 150px;
  }
}
@media screen and (max-width: 280px) {
  .berita__slide div {
    width: 100px;
  }
}

.berita__comp {
  display: flex;
  margin: 10px 0;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
  height: 200px;
}
.berita__comp img {
  border-radius: 20px 0 0 20px;
  width: 30%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.berita__comp div {
  width: 70%;
  text-align: left;
  padding: 10px;
}
.berita__comp div h1 {
  font-size: 24px;
  font-weight: 600;
  color: #f73859;
  margin-bottom: 10px;
}
.berita__comp div p {
  font-size: 16px;
  font-weight: 300;
  padding: 0;
  margin: 0;
  color: rgba(35, 41, 49);
}

@media screen and (max-width: 790px) {
  .berita__comp div h1 {
    font-size: 18px;
  }
  .berita__comp div p {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .berita__comp div h1 {
    font-size: 14px;
  }
  .berita__comp div p {
    font-size: 9px;
  }
  .berita__comp img {
    width: 30%;
  }
  .berita__comp div {
    width: 70%;
  }
  .berita__comp {
    height: 150px;
  }
}
@media screen and (max-width: 450px) {
  .berita__comp div h1 {
    font-size: 12px;
  }
  .berita__comp div p {
    font-size: 8px;
  }
  .berita__comp img {
    width: 30%;
  }
  .berita__comp div {
    width: 70%;
  }
  .berita__comp {
    height: 120px;
  }
}
@media screen and (max-width: 360px) {
  .berita__comp div h1 {
    font-size: 11px;
  }
  .berita__comp div p {
    font-size: 7px;
  }
  .berita__comp img {
    width: 35%;
  }
  .berita__comp div {
    width: 65%;
  }
  .berita__comp {
    height: 120px;
  }
}
@media screen and (max-width: 330px) {
  .berita__comp div h1 {
    font-size: 10px;
  }
  .berita__comp div p {
    font-size: 6px;
  }
  .berita__comp img {
    width: 30%;
  }
  .berita__comp div {
    width: 70%;
  }
  .berita__comp {
    height: 120px;
  }
}

.data {
  width: 100%;
  flex: 1 0 auto;
}
.body__data {
  margin-top: 70px;
  display: block;
  padding: 20px 30px;
  text-align: center;
}
.body__data h1 {
  width: 100%;
  text-align: center;
  color: #232931;
  font-weight: 800;
  font-size: 40px;
  -webkit-text-decoration: underline #f73859;
          text-decoration: underline #f73859;
}
.body__data div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}
table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: separate;
  border-spacing: 0 0.5em;
}
tr {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(35, 41, 49, 0.4);
  margin-bottom: 10px;
}

th {
  padding: 10px 30px;
  color: #f73859;
  text-align: left;
}

/* .data__rtrw {
  width: 20%;
  border-radius: 20px;
  padding: 20px 10px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.4);
  margin: 5px;
}
.data__rtrw p {
  text-align: left;
  color: rgb(35, 41, 49);
}
.data__rtrw p span {
  color: #f73859;
  font-weight: 500;
}

@media screen and (max-width: 975px) {
  .data__rtrw {
    width: 27%;
  }
}
@media screen and (max-width: 736px) {
  .data__rtrw {
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .data__rtrw {
    width: 90%;
  }
} */

td {
  padding: 10px 30px;
  text-align: left;
}

/* .data__comp {
  width: 20%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.4);
  margin: 5px;
}
.data__comp div {
  padding: 20px 10px;
}
.data__comp div p {
  text-align: left;
}
.data__comp div p span {
  color: #f73859;
  font-weight: 500;
} */

td img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 10px 10px;
}
td {
  padding: 10px 30px;
  text-align: left;
}

/* @media screen and (max-width: 975px) {
  .data__comp {
    width: 27%;
  }
}
@media screen and (max-width: 736px) {
  .data__comp {
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .data__comp {
    width: 90%;
  }
} */

.profil {
  width: 100%;
  flex: 1 0 auto;
}
.body__profil {
  margin-top: 70px;
  display: block;
  padding: 20px 30px;
  text-align: center;
}

.body__profil h1 {
  width: 100%;
  text-align: center;
  color: #232931;
  font-weight: 800;
  font-size: 40px;
  -webkit-text-decoration: underline #f73859;
          text-decoration: underline #f73859;
}
.body__profil img {
  margin: 10px;
  width: 80%;
  object-fit: cover;
  object-position: center;
  height: auto;
  border-radius: 10px;
}

.body__profil p {
  margin: 10px 0 30px 0;
}

.detail__berita {
  width: 100%;
  flex: 1 0 auto;
}
.body__detail__berita {
  background-color: #ededed;
  display: block;
  margin: 100px 30px 30px 30px;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(35, 41, 49, 0.2);
  text-align: center;
}

.body__detail__berita div {
  width: 100%;
  text-align: left;
  display: block;
}

.body__detail__berita div h1 {
  font-size: 42px;
}

.body__detail__berita div span {
  font-size: 10px;
}
.body__detail__berita div span b {
  color: #f73859;
}

.body__detail__berita div img {
  padding: 15px 0;
  max-width: 100%;
  max-height: 500px;
  object-fit: contain;
}

.body__detail__berita div p {
  text-align: justify;
  font-size: 16;
}

